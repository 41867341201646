<template>
    <div >
      <div class="d-flex" >
        <v-tooltip bottom content-class="tooltip-bottom">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="" name="conversations" v-bind="attrs" v-on="on" icon small @click="getProjectCon(true)">
              <v-badge color="error" offset-x="10" offset-y="10" top dot :value="notify">
                <img src="/img/icons/ConversationIcon.svg" alt="" srcset="" width="30px" height="22px">
              </v-badge>
            </v-btn>
          </template>
          <span>Comments</span>
        </v-tooltip>
        <span class="ml-1" @click="getProjectCon(true)" >{{name}}</span>
      </div>
      <v-dialog v-model="dialog" class="v-dialog h-75" scrollable :max-width="width" :max-height="600" style="max-height: 500px !important;">
        <v-card :loading="loading" :disabled="loading" loader-height="2" style="max-height:600px !important">
          <v-card-text class=" d-flex align-center py-4 px-4 overflow-hidden ">
            <span class="subtitle-1 ">{{ heading }}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="showUser=false ;dialog = false" class="">
              <v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text class="px-1 py-1" @scroll="menu=false">
            <div>
              <div v-for="(item, index) in conversationList" :key="index">
                <!-- Receiver  - you-->
                <div color="rgba(0,0,0,0)" flat class="mb-2 mr-4 d-flex flex-column align-end justify-end" v-if="item.user == $store.state.user.id">

                    <div bottom  class="justify-space-between align-center d-flex " style="width: 50%;">
                        
                        <!-- <span class="font-weight-bold">{{ item.user_detail.name }}</span> -->
                        <span class="font-weight-bold">You</span>
                        <div class="timeFont ">
                            {{
                              $nova.formatDateTime(item.created_at, "DD MMM yyyy hh:mm A")
                            }}
                          </div>
                    </div>
                  <div class="text-left w-50 line-1  jutify-end" style="width: 50%;">
                    
                    <v-card elevation="8" dark class=" rounded-lg rounded-tr-0 outlined"
                      max-width="500px" color="caccounting">
                      <v-card-text class="py-1 px-3">
                        <span v-html="formattedItemValue(item.value)" class="white--text py-1 text-wrap" style=" word-break: break-all;"></span>
                      </v-card-text>
                    </v-card>                    
                  </div>                 
                </div>
                <!-- sender - other person -->
                <div color="rgba(0,0,0,0)" flat class=" mb-2 d-flex flex-column align-start justify-start" v-else>
                  <div bottom v-if="(conversationList[index-1] || {}).user !== item.user" class="justify-space-between align-center d-flex " style="width: 56%;">
                    <div class="text-center d-flex justify-center align-center gap-2" @scroll="menu=false">
                      <v-menu v-model="menu" :close-on-content-click="false" :close-on-content-scroll="true" location="end">
                        <template v-slot:activator="{ on, attrs }">
                          <v-avatar
                            class="ml-2 cursor-pointer"
                            :key="item.user_detail._id"
                            :size="30"
                            :elevation="10"
                            color="caccounting"
                            v-bind="attrs"
                            v-on="on"
                            @click="selectUser(item)"
                          >
                          <v-img
                            v-if="item.user_detail.profile_url"
                            alt="Avatar"
                            width="30px"
                            height="30"
                            :size="30"
                            :src="item.user_detail.profile_url"
                          ></v-img>
                          <span class="white--text" v-else >{{ item.user_detail.name[0] }}</span>
                        </v-avatar>
                        </template>
                        <v-card min-width="300" v-if="selectedUser == item">
                          <v-list>
                            <v-list-item class="d-flex  align-start justify-start">
                              <v-avatar color="primary" class="white--text"> 
                                <span class="white--text"  v-if="selectedUser.user_detail.profile === false">{{ selectedUser.user_detail.name[0]}}</span>
                                <v-img
                                  v-else
                                  alt="Avatar"
                                  :src="selectedUser.user_detail.profile"
                                ></v-img>
                              </v-avatar>
                              <div class="d-flex flex-column px-3 justify-center">
                                <p class="font-weight-bold  ma-1"> {{ selectedUser.user_detail.name}} </p>  
                                <p class="ma-1 "> {{ selectedUser.user_detail.email}} </p>
                                <p class="ma-1 py-1"> {{ selectedUser.user_detail.contactno}} </p>
                              </div>
                              <v-btn
                                  @click="copyText()"
                                > <v-icon>mdi-content-copy</v-icon></v-btn>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-menu>
                      <span  class="font-weight-bold">{{ item.user_detail.name }}</span>
                    </div>
                    <div class="text-left line-1  "  >{{
                      $nova.formatDate(item.created_at, "DD MMM yyyy h:mm A")
                    }}</div>
                  </div>
                  <div class="ml-10" style="width: 50%;">
                    
                    <v-card elevation="8" class=" rounded-lg rounded-tl-0 outlined" 
                      color="blue-grey lighten-5">
                      <v-card-text class="py-1 px-3">
                        <span v-html="formattedItemValue(item.value)" class="py-1" style=" word-break: break-all;"></span>   
                      </v-card-text>
                    </v-card>
                    
                  </div>
                </div>
              </div>
              <div v-if="(conversationList || []).length === 0" class="title text-center my-5 grey--text">
                No Conversation added yet
              </div>
            </div>
          </v-card-text>
          <v-card-actions v-if="!closed" class="px-0">
            <v-card-text class="px-2 border-top" style="border-top: 1px solid #EAECF0;">
              <div class="d-flex align-center mb-1" v-if="recurring">
                <v-checkbox label="Reucurring Comment" class="mr-2 pa-0 ma-0" dense hide-details
                  v-model="recurringMsg"></v-checkbox>
                <v-tooltip right content-class="tooltip-right">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="blue-grey lighten-2" dark v-bind="attrs" v-on="on">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span class="text-justify">{{ recurringtext }}</span>
                </v-tooltip>
              </div>
              <div class="">
              <div class="d-flex align-center">
                <lb-string label="Message" hidedetails @enter="sendQuery()" v-model="queryMsg" :isRequired="true"/>
                <div   class="ml-3 rounded-lg d-flex align-center justify-center cursor-pointer caccounting pa-2" :loading="loading" @click="sendQuery()">
                    <img
                    src="/img/icons/send.svg"
                    class=""
                    max-width="20px"
                    max-height="20px"
                    alt="Send"
                  />
                  </div>
                </div>
                <div class="usersDropdown" v-if="showUser">
                  <div class="d-flex flex-column  justify-center align-start ">
                      <v-list style=" width:100%" class="pl-1 radius-small" v-if="filteredUsers.length>0">
                        <v-list-item
                          v-for="(item, index) in filteredUsers"
                          :key="index"
                          :value="index"
                          class="cursor-pointer hoverable py-1"
                          style="max-height: 3vh; width:100%"
                          @click="UserMentionedName(item)"
                        >
                        <v-avatar color="caccounting" class="white--text mr-3 avatar"  >
                          {{ item[0] }}
                        </v-avatar>
                        <v-list-item-title  >{{ item }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                      <v-list v-else class="d-flex align-center justtify-center">
                        <v-list-item style="max-height: 3vh; width:100%">No user found </v-list-item>
                      </v-list>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card-actions>
        </v-card>      </v-dialog>
    </div>
  </template>
  <script>
  export default {
    name: "conversations",
    data() {
      return {
        dialog: false,
        loading: false,
        conversationList: [],
        queryMsg: "",
        recurringMsg: false,
        users:[],
        showUser:false,
        menu: false,
        selectedUser:null,
        mentionedUser:[],
        filteredUsers:[]
      };
    },
    props: {
      getapi: {
        type: String,
        default: "",
      },
      mentionapi: {
        type: String,
        default: "",
      },
      sendapi: {
        type: String,
        default: "",
      },
      readapi: {
        type: String,
        default: "",
      },
      heading: {
        type: String,
        default: "Conversation",
      },
      recurring: {
        type: Boolean,
        default: false,
      },
      recurringtext: {
        type: String,
        default: "Requirements comments for next time",
      },
      notify: {
        type: Boolean,
        default: false,
      },
      width: {
        type: String,
        default: "800",
      },
      height: {
        type: String,
        default: "600",
      },
      type: {
        type: String,
        default: "string",
      },
      closed: {
        type: Boolean,
        default: true,
      },
      highlight: {
        type: Boolean,
        default: false
      },
      name:
      {
        type: String,
        default:""
      }
    },
    methods: {
      selectUser(item) 
      {
        this.selectedUser = item;
      },
      async copyText()
      {
        const userDetails = `
          Name: ${this.selectedUser.user_detail.name}
          Email: ${this.selectedUser.user_detail.email}
          Contact No: ${this.selectedUser.user_detail.contactno}
        `;
        await navigator.clipboard.writeText(userDetails);
        this.$emit('notify', 'copied to clipboard!');
      },
    formattedItemValue(value) {
      
      let formattedValue = value;
        this.users.forEach(user => {
          const regex = new RegExp(`(${user})`, 'gi'); 
          formattedValue = formattedValue.replace(regex, '<b>$1</b>');
        });
        return formattedValue;
      },
      sendQuery() {
        if(this.queryMsg){
          this.loading = true;
          let dataObject = {
            type: this.type,
            value: this.queryMsg,
            status: this.status,
          };
          // console.log(dataObject);
          let successflag = false;
          this.axios
            .post(this.sendapi, { data: dataObject })
            .then((dt) => {
              if (dt.data.status === "success") {
                successflag = true;
                // this.$store.commit("sbSuccess", "successfully sended");
                this.queryMsg = "";
              }else throw Error(dt.data.message || "Unknown error")
            })
            .catch((err) => {
              this.$store.commit("sbError", err.message || err || "Unknown error!");
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
              if(successflag) this.getProjectCon();
            });
        }
      },
      getProjectCon(mark=false) {
        this.dialog = true;
        this.loading = true;
        this.axios
          .post(this.getapi)
          .then((dt) => {
            if (dt.data.status === "success") {
              this.conversationList = dt.data.data;
              if(mark) this.markAsRead();
              // Call mentionapi only if getapi is successfull
              this.axios.post(this.mentionapi)
                .then((response) => {
                  this.users = response.data.data[0].users.map(item=>item.name)
                  this.filteredUsers = response.data.data[0].users.map(item=>item.name)
                })
                .catch((err) => {
                  this.$store.commit("sbError", err.message || err || "Unknown error!");
                  console.log(err);
                });
            } else {
              throw Error(dt.data.message || "Unknown error");
            }
          })
          .catch((err) => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      markAsRead() {
        this.axios
          .post(this.readapi)
          .then((dt) => {
            if (dt.data.status === "success") {
              this.$emit("markread");
            }else throw Error(dt.data.message || "Unknown error")
            // console.log(this.conversationList);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
          });
      },
      UserMentionedName(username){
        let atIndex = this.queryMsg.lastIndexOf('@');
        if (atIndex !== -1) {
            this.queryMsg = this.queryMsg.slice(0, atIndex );
        }
        this.queryMsg += username + " ";
        this.mentionedUser.push(username);
        this.showUser = false;
      },
      filterUser()
      {
        const atIndex = this.queryMsg.lastIndexOf("@");
        if (atIndex !== -1) {
          const searchTerm = this.queryMsg.slice(atIndex + 1).toLowerCase();
          if (searchTerm) {
            this.filteredUsers = this.users.filter(user => user.toLowerCase().startsWith(searchTerm));
          } else {
            this.filteredUsers = this.users;
          }
        } else {
          this.filteredUsers = this.users;
        }
    },
  },
    watch:{
      queryMsg(i){
        let lastIndex = i[i.length-1]
        if(lastIndex == "@" )
        {
          this.showUser=true
        }
        else if (!this.queryMsg.includes('@') )
        {
          this.showUser = false;
        }
        this.filterUser()
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .timeFont {
    font-size: 9px !important;
  }
  .gap-2
  {
    gap:4px;
  }
  .v-btn--icon, .v-size--small, .v-btn__content
  {
    height: 20px  !important;
    width: 20px  !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .custom-tooltip {
    background-color: white !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
    border-radius: 4px;
    padding: 10px;
    color: black;
    margin-bottom: -20px !important;
  }

  .custom-tooltip .v-tooltip__content {
    pointer-events: auto !important;
  }

  .usersDropdown{
    z-index: 100;
    overflow-y: scroll;
    background: white;
    border: 1px solid grey;
    position: fixed;
    max-height: 15vh;
    border-radius: 8px;
    width: 20vh;
  }
  .avatar
  {
    height: 32px !important; 
    min-width: 32px !important; 
    width: 32px !important;
  }

  .highlightText {
    font-weight: bold;
  }
  .userDetail
  {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 10px;
    padding: 10px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    white-space: nowrap;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px 0px inset, rgba(17, 17, 26, 0.05) 0px 8px 32px 0px;
  }
  </style>
  
  